import React from "react"
import { Badge } from "react-bootstrap"

const DetailsRando = ({ presta, imgWidth, spaceImgTxt, isGeneratedPage = false }) => {

    const modifyRelativePath = isGeneratedPage ? "." : ""

    return (
        <div className="d-flex justify-content-between align-items-center">
            <span><img style={{ width: imgWidth }} src={"." + modifyRelativePath + "/images/distance.svg"} alt={""} /><span style={{ marginLeft: spaceImgTxt }}>{presta.distance + " km"}</span></span>
            <span><img style={{ width: imgWidth }} src={"." + modifyRelativePath + "/images/mountain.svg"} alt={""} /><span style={{ marginLeft: spaceImgTxt }}>{presta["d+"] + " m"}</span></span>
            <span><img style={{ width: imgWidth }} src={"." + modifyRelativePath + "/images/clock.svg"} alt={""} /><span style={{ marginLeft: spaceImgTxt }}>{presta.temps}</span></span>
            {presta.isFamily ? <Badge className="d-inline-flex align-items-center justify-content-start" style={{ backgroundColor: "#A0A0A0", color: "black", height: "1.3rem" }}><span>famille</span></Badge> : <></>}
        </div>
    )
}

export default DetailsRando